<template>
  <div>
    <Grid
      :service="service"
      route_name="appeal_handlings"
      order_column="created_at">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="user_name" sort-key="user_name">{{ $t('appeal.requester') }}</vs-th>
        <vs-th key="protocol_number" sort-key="protocol_number">{{ $t('appeal.protocol') }}</vs-th>
        <vs-th key="model_name" sort-key="model_name">{{ $t('appeal.questionnaire') }}</vs-th>
        <vs-th key="created_at" sort-key="created_at">{{ $t('appeal.created_at') }}</vs-th>
        <vs-th key="status" sort-key="status">{{ $t('appeal.status') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="20%" key="user_name">
            <span> {{ tr.appeal.user.name}}</span>
            <span class="text-sm"> {{ tr.appeal.user.email}}</span>
          </vs-td>
          <vs-td width="20%" key="protocol_number">
            <span> {{ tr.appeal.protocol_number }}</span>
          </vs-td>
          <vs-td width="30%" key="model_name">
            <span> {{ tr.appeal.model_name }}</span>
          </vs-td>
          <vs-td width="10%" key="created_at">
            <span> {{ tr.appeal.created_dt_fmt }}</span>
          </vs-td>
          <vs-td width="10%" key="status">
            <span> {{ tr.status_str }}</span>
          </vs-td>
          <vs-td width="10%">
            <span class="flex flex-row justify-center gap-1">
              <feather-icon
                v-permission="'appeal_handlings.show'"
                icon="EyeIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                @click="detail(props.data[indextr].id)"
                v-tooltip.top="{content: 'Detalhar Recurso',delay: { show: 1000, hide: 100 }}"/>

              <feather-icon
                v-permission="'appeal_handlings.edit'"
                icon="EditIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="handling(props.data[indextr].id)"
                v-tooltip.top="{content: 'Tratar Recurso',delay: { show: 1000, hide: 100 }}"/>
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import AppealHandlingService from '@/services/api/AppealHandlingService'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    detail(id) {
      this.$router.push(`/appeal_handlings/${id}`)
    },
    handling(id) {
      this.$router.push(`/appeal_handlings/${id}/handling`)
    }

  },
  beforeMount() {
    this.service = AppealHandlingService.build(this.$vs)
  }
}
</script>
