import Rest from '../Rest'

export default class AppealHandlingService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/appeal_handlings'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  updateHandling(id: Number, fields: any) {
    return this.post(`${id}/handling`, fields).then(data => {
      return data
    })
  }

  listHandlingHistory(id: Number) {
    return this.get(`${id}/historys`).then(data => {
      return data
    })
  }

}
